<script>
  import { Router, Route, Link } from "svelte-routing";
  import Dashboard from "./routes/Dashboard.svelte";
  import Reports from "./routes/Reports.svelte";
  import AlarmViewer from "./routes/AlarmViewer.svelte";
  import DigitalTwin from "./routes/DigitalTwin.svelte";
  import SetAlarm from "./routes/SetAlarm.svelte";
  import About from "./routes/About.svelte";
  import Error from "./routes/Error.svelte";

  import { onMount, onDestroy } from "svelte";
  import {
    selectedNode,
    defaultNodeId,
    allocatedScreenNames,
    isPageTitleDelpheon,
    companyName,
    selectedDate,
    currentPage,
    applicationName,
    companyDetails,
    startTimeOffSet,
    endTimeOffSet,
    parentNodeType,
    levelNodeType,
    roomNodeType,
    childNodeType,
    isReportGenerated,
    applicationError,
  } from "./stores.js";
  import { getDomainURL, invokeAPI } from "./invokeAPI.js";
  import DelNavigationTree from "./components/DelNavigationTree/DelNavigationTree.svelte";
  import DelDatePicker from "./components/DelDatePicker/DelDatePicker.svelte";
  import {
    pagesWithDatePicker,
    screensToShow,
    pathNameSSPA,
  } from "./../config";
  import { ENVIRONMENT } from "./../environment";
  import moment from "moment";

  const IS_STANDALONE = window.singleSpaNavigate ? false : true;

  let defaultStartDate = sessionStorage.getItem("saved-start-date")
    ? sessionStorage.getItem("saved-start-date")
    : new Date(moment().subtract(6, "day").startOf("day"));
  let defaultEndDate = sessionStorage.getItem("saved-end-date")
    ? sessionStorage.getItem("saved-end-date")
    : new Date(moment().endOf("day"));

  let domainURL = getDomainURL();
  let initialScreenPath = "";
  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;
  $: unclickableNodes =
    $currentPage === "/"
      ? [$parentNodeType, $levelNodeType, $roomNodeType]
      : [];

  // Define the custom event
  const logOutEvent = new Event("logoutEvent", {
    bubbles: true,
  });

  // Store the original fetch function
  const originalFetch = window.fetch;

  window.fetch = async function (...args) {
    let response = null;
    try {
      response = await originalFetch(...args);
    } catch (error) {
      console.error("API error", error);
      if (error.message === "Failed to fetch") {
        console.error("Network error: logging out...", error);
        window.dispatchEvent(logOutEvent);
      } else {
        console.error("Fetch error:", error);
      }
    }

    if (response && response.status === 401) {
      console.error("401 Unauthorized Error, logging out...");
      window.dispatchEvent(logOutEvent);
    }

    return response;
  };

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };

  if (!checkIfTodayHasStarted($startTimeOffSet)) {
    if (!dateFromSessionStorage) {
      defaultStartDate = new Date(moment(defaultStartDate).subtract(1, "day"));
      defaultEndDate = new Date(moment(defaultEndDate).subtract(1, "day"));
    }
  }

  const invokeGetCompanyDetailsAPI = function () {
    const endpoint = `twin/api/Hierarchy/${$companyName}/${$applicationName}`;
    const companyDetailsAPIOnSuccess = function (data) {
      companyDetails.set(data);
      startTimeOffSet.set(data?.StartTimeOffset ?? 0);
      endTimeOffSet.set(data?.EndTimeOffset ?? 0);
      parentNodeType.set(data?.metadata?.length ? data.metadata[0].type : "");
      levelNodeType.set(
        data?.metadata?.length > 1 ? data.metadata[1].type : ""
      );
      roomNodeType.set(data?.metadata?.length > 2 ? data.metadata[2].type : "");
      childNodeType.set(
        data?.metadata?.length > 3 ? data.metadata[3].type : ""
      );
      localStorage.setItem("startTimeOffset", $startTimeOffSet);
      localStorage.setItem("endTimeOffset", $endTimeOffSet);
    };

    invokeAPI(
      "GET",
      endpoint,
      companyDetailsAPIOnSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null
    );
  };

  const onURLChanged = function (event) {
    console.log("URL changed:", window.location.pathname);
    const currentPath = window.location.pathname;
    let newPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    newPath = newPath || "/";
    handleLinkClick(newPath);
  };

  onMount(() => {
    const currentPath = window.location.pathname;
    window.addEventListener("popstate", onURLChanged);
    initialScreenPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    initialScreenPath = initialScreenPath || "/";
    currentPage.set(initialScreenPath);
    if (initialScreenPath !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (initialScreenPath !== "/Events") {
      clearEventsRelatedSessionItems();
    }
    invokeGetCompanyDetailsAPI();
  });

  onDestroy(() => {
    window.removeEventListener("popstate", onURLChanged);
  });

  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];

  const onDateChange = function (newDate) {
    selectedDate.set(newDate);
    sessionStorage.setItem("saved-start-date", newDate?.start);
    sessionStorage.setItem("saved-end-date", newDate?.end);
  };

  const onNavTreeNodeSelected = function (node) {
    selectedNode.set(node);
    sessionStorage.setItem("saved-node-id", node?.id);
  };

  const checkIfScreenIsNotAllocated = function (screen) {
    if ($allocatedScreenNames?.includes(screen)) return false;
    return true;
  };

  const clearReportRelatedSessionItems = function () {
    sessionStorage.removeItem("report-to-be-shown");
    sessionStorage.removeItem("report-selected-start-time");
    sessionStorage.removeItem("report-selected-end-time");
    sessionStorage.removeItem("report-selected-options");
    sessionStorage.removeItem("report-selected-summary-options");
    sessionStorage.removeItem("report-default-selected-options");
    isReportGenerated.set(false);
  };

  const clearEventsRelatedSessionItems = function () {
    sessionStorage.removeItem("eventViewerFilterColumns");
    sessionStorage.removeItem("eventViewerSelectedFilters");
    sessionStorage.removeItem("isChildNodeIncluded");
  };

  const handleLinkClick = function (screenName) {
    currentPage.set(screenName);
    if (screenName !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (screenName !== "/Events") {
      clearEventsRelatedSessionItems();
    }
  };

  const onNoNodesAssigned = function () {
    applicationError.set({
      error: true,
      message:
        "No user nodes assigned for this user. Please contact your admin.",
      disable: true,
    });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify($applicationError)
    );
    if ($currentPage !== "/Error") window.location.href = `${pathPrefix}/Error`;
  };

  const onNavTreeAPISucess = function () {
    applicationError.set({ error: false });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify({ error: false })
    );
  };
</script>

<svelte:head>
  <title>{$isPageTitleDelpheon === "true" ? "DelphEon" : $companyName}</title>
</svelte:head>

<div class="del-application-container" id="del-application-container">
  <div class="del-navigation-bar">
    <div class="del-nav-bar-left-container">
      <div class="del-nav-tree-container">
        <div class="del-nav-tree">
          <DelNavigationTree
            onNodeSelected={onNavTreeNodeSelected}
            baseURL={domainURL}
            defaultNodeId={$defaultNodeId}
            currentScreen={screensToShow[initialScreenPath]?.screen ?? null}
            isDisabled={$isReportGenerated}
            {onNoNodesAssigned}
            onAPISuccess={onNavTreeAPISucess}
            unClickableNodeTypes={unclickableNodes}
            setLeafNodeAsClickable={true}
          />
        </div>
        <div class="del-node-name">{$selectedNode?.path ?? ""}</div>
      </div>
    </div>
    <div class="del-nav-item-container">
      <Router>
        {#each Object.keys(screensToShow) as screenName (screenName)}
          {#if screenName !== "/Infoboard" && screenName !== "/Error"}
            <Link
              to={`${pathPrefix}${screenName}`}
              class="del-nav-item {checkIfScreenIsNotAllocated(
                screensToShow[screenName].screen
              ) || $applicationError?.error
                ? 'disabled'
                : ''} {$currentPage === screenName
                ? 'del-active-nav-item'
                : ''} "
              on:click={() => handleLinkClick(screenName)}
              >{screensToShow[screenName].displayName}</Link
            >
          {/if}
        {/each}
      </Router>
      <About />
    </div>
  </div>

  <Router basepath={IS_STANDALONE ? "/" : pathNameSSPA}>
    <Route path="/" component={Dashboard} />
    <Route path="/AlarmViewer" component={AlarmViewer} />
    <Route path="/DigitalTwin" component={DigitalTwin} />
    <Route path="/SetAlarm" component={SetAlarm} />
    <Route path="/Reports" component={Reports} />
    <Route path="/Error" component={Error} />
  </Router>
</div>

<style>
  :global(body) {
    margin: 0 auto;
    padding: 0;
  }
  :global(.del-application-container) {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    background-color: #f7f8fa;
    height: 100%;
  }
  .del-navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 28px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #0000001f;
    opacity: 1;
    border-top: 1px solid #ccd3e7;
    min-height: 40px;
  }

  .del-nav-bar-left-container {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  .del-date-picker-container {
    align-self: center;
  }
  .del-nav-tree-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .del-node-name,
  .del-nav-tree {
    align-self: center;
  }

  .del-nav-tree {
    height: 32px;
  }

  :global(.tree-cascader-button),
  :global(.tree-cascader-button:enabled:hover),
  :global(.tree-cascader-button:enabled:active),
  :global(.tree-cascader-button:enabled:focus) {
    background-color: #ffffff !important;
    color: rgb(0, 0, 0) !important;
    width: 32px !important;
    height: 32px !important;
    padding: 5px !important;
  }

  :global(.tree-cascader-button:disabled) {
    background-color: #ddddddbd !important;
    color: white !important;
  }

  .del-node-name {
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    opacity: 1;
  }

  .del-nav-item-container {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  :global(a.del-nav-item) {
    text-decoration: none;
    color: #585859;
  }

  :global(.del-nav-item:hover) {
    color: #f37a25;
  }
  :global(.del-nav-item) {
    text-align: left;
    font: normal normal 500 15px/20px Roboto;
    letter-spacing: 0px;
    color: #585859;
    opacity: 1;
    cursor: pointer;
    text-decoration: none;
  }

  :global(.del-nav-item.disabled) {
    cursor: not-allowed;
    pointer-events: none;
    color: #59585875;
  }

  :global(a.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #f37a25;
    opacity: 1;
    text-decoration: none;
  }

  :global(.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #f37a25;
    opacity: 1;
  }

  :global(.del-button) {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  :global(.del-button:hover),
  :global(.del-button:focus),
  :global(.del-button:active) {
    background-color: transparent;
  }

  :global(.del-page-container) {
    padding-bottom: 16px;
  }

  :global(.del-container) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
  }
</style>
