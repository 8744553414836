<script>
  import DelEventTable from "../components/DelEventTable/DelEventTable.svelte";
  import {
    selectedNode,
    defaultNodeId,
    currentPage,
    availableScreens,
  } from "../stores";
  import { screensToShow } from "./../../config.js";
  import { getDomainURL } from "../invokeAPI";
  import { ENVIRONMENT } from "./../../environment";

  $: selectedNodeId = $selectedNode?.id;

  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;

  const eventTableColumns = {
    alertname: "Event Name",
    category: "Category",
    nodeName: "Room",
    description: "Description",
    alertvalue: "Alert Value",
    createdDate: "Time",
    acknowledgedDate: "Acked On",
    acknowledgedBy: "Acked By",
    isAcknowledged: "Ack Status",
    action: "Action",
    ignore: "Disable",
  };

  const filterableCol = {
    category: ["Critical", "Warning", "Informational"],
    nodeName: [],
    isAcknowledged: ["YES", "NO"],
  };

  const sortableCol = [
    "alertname",
    "category",
    "createdDate",
    "acknowledgedDate",
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const nodeIdFromURL = urlParams.get("nodeId");

  if (nodeIdFromURL) {
    defaultNodeId.set(nodeIdFromURL);
  }

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);

  const navigateToDigitalTwin = function (nodeId) {
    defaultNodeId.set(nodeId);
    sessionStorage.setItem("saved-node-id", nodeId);
    window.location.href = `${pathPrefix}/DigitalTwin`;
  };
</script>

<div class="del-event-viewer-container">
  <DelEventTable
    currentNodeId={selectedNodeId}
    selectedNodeDetails={$selectedNode}
    baseURL={getDomainURL()}
    tableHeadings={eventTableColumns}
    isWritePermission={screenPermission === "RW"}
    filterColumns={filterableCol}
    sortColumns={sortableCol}
    isAlertRedirectable={true}
    alertNameClicked={navigateToDigitalTwin}
  />
</div>

<style>
  .del-event-viewer-container {
    padding: 32px 16px;
  }
</style>
