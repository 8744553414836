<script>
  import moment from "moment";
  import DelDatePicker from "../components/DelDatePicker/DelDatePicker.svelte";
  import {
    selectedNode,
    selectedDate,
    currentPage,
    availableScreens,
    parentNodeType,
    isReportGenerated,
  } from "./../stores";
  import { getDomainURL } from "./../invokeAPI";
  import { addOffSet, epoch13to10 } from "./../utilityFunctions";
  import {
    eventReportConfig,
    rawReportConfig,
    screensToShow,
  } from "./../../config";
  import DelDropDownCheckBox from "../components/DelDropDownCheckBox/DelDropDownCheckBox.svelte";
  import DelEventReport from "../components/DelEventReport/DelEventReport.svelte";
  import DelSLAReport from "./../components/DelSLAReport/DelSLAReport.svelte";
  import DelRawReport from "./../components/DelRawReport/DelRawReport.svelte";
  import DelTimePickerPopUp from "../components/DelTimePickerPopUp/DelTimePickerPopUp.svelte";
  import "toastify-js/src/toastify.css";

  let nodesList;
  let selectedNodeList = [];
  let defaultSeletionNodeList = [];

  $: {
    if ($selectedNode) {
      clearDefaultOptions();
      getLeafNodes([$selectedNode]);
      selectedNodeList.forEach((item) => {
        defaultSeletionNodeList.push(item.id);
      });
    }
  }

  $: {
    if ($selectedNode || $selectedDate) {
      closeAllDropDowns();
      onPopUpCancelClick();
    }
  }

  $: disableSubmitButton =
    !$selectedNode || $isReportGenerated || !selectedReport;

  const closeAllDropDowns = function () {
    isAlarmHistoryDropDownOpen = false;
    isRawReportDropDownOpen = false;
  };

  let eventDefaultOptions = [];
  let rawReportDefaultOptions = [];

  let isAlarmHistoryDropDownOpen = false;
  let isRawReportDropDownOpen = false;

  let defaultStartDate = sessionStorage.getItem("saved-start-date")
    ? sessionStorage.getItem("saved-start-date")
    : new Date(moment().startOf("day"));
  let defaultEndDate = sessionStorage.getItem("saved-end-date")
    ? sessionStorage.getItem("saved-end-date")
    : new Date(moment());

  let reportToBeShown = sessionStorage.getItem("report-to-be-shown");
  let reportStartTime = sessionStorage.getItem("report-selected-start-time");
  let reportEndTime = sessionStorage.getItem("report-selected-end-time");
  let reportSelectNodeId = sessionStorage.getItem("report-selected-node-id");
  let reportSelectNode = sessionStorage.getItem("report-selected-node")
    ? JSON.parse(sessionStorage.getItem("report-selected-node"))
    : null;
  let reportSelectedNodeName = sessionStorage.getItem(
    "report-selected-node-name"
  );
  let selectedOptions = sessionStorage.getItem("report-selected-options")
    ? JSON.parse(sessionStorage.getItem("report-selected-options"))
    : [];
  let defaultOptions = sessionStorage.getItem("report-default-selected-options")
    ? JSON.parse(sessionStorage.getItem("report-default-selected-options"))
    : [];
  let selectedSummaryOptions = sessionStorage.getItem(
    "report-selected-summary-options"
  )
    ? JSON.parse(sessionStorage.getItem("report-selected-summary-options"))
    : [];

  let selectedReport = null;
  let buttonStatus;

  let popUpStartTime = null;
  let popUpEndTime = null;
  let showPopUp = false;

  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];

  const onDateChange = function (newDate) {
    selectedDate.set(newDate);
    sessionStorage.setItem("saved-start-date", newDate?.start);
    sessionStorage.setItem("saved-end-date", newDate?.end);
  };

  const setDefaultOptions = function () {
    switch (reportToBeShown) {
      case "Raw Report":
        rawReportDefaultOptions = [...defaultOptions];
        break;
      case "Alarm History":
        eventDefaultOptions = [...defaultOptions];
        break;
      default:
        rawReportDefaultOptions = [];
        eventDefaultOptions = [];
        break;
    }
  };

  setDefaultOptions();

  const clearDefaultOptions = function (type) {
    switch (type) {
      case "Alarm History":
        rawReportDefaultOptions = [];
        break;
      case "Raw Report":
        eventDefaultOptions = [];
        break;
      default:
        eventDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
    }
  };

  const onEventReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = selected.map((item) => item.value);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selectedOptions)
    );
    if (selected.length) selectedReport = "Alarm History";
  };
  const onRawReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = selected.map((item) => item.value);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selectedOptions)
    );
    if (selected.length) selectedReport = "Raw Report";
  };

  const onPopUpSubmitClick = function (start, end, selected) {
    if (selected) nodesList = selected;

    reportSelectNodeId = $selectedNode?.id || "";
    reportSelectedNodeName = $selectedNode?.name || "";
    reportSelectNode = { ...$selectedNode };

    sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
    sessionStorage.setItem("report-selected-node-name", reportSelectedNodeName);
    sessionStorage.setItem(
      "report-selected-node",
      JSON.stringify(reportSelectNode)
    );

    reportToBeShown = selectedReport;
    reportStartTime = start || 0;
    reportEndTime = end || 0;

    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;

    sessionStorage.setItem("report-to-be-shown", reportToBeShown);
    sessionStorage.setItem("report-selected-start-time", reportStartTime);
    sessionStorage.setItem("report-selected-end-time", reportEndTime);
  };

  const onPopUpCancelClick = function () {
    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;
  };

  const onSubmitButtonClick = function () {
    closeAllDropDowns();
    switch (selectedReport) {
      case "Alarm History":
        popUpStartTime = $selectedDate?.dayDefinedStartDate || 0;
        popUpEndTime =
          $selectedDate?.dayDefinedEndDateInEpochNotGreaterThanNow || 0;
        showPopUp = true;
        break;
      case "Raw Report":
        popUpStartTime = $selectedDate?.dayDefinedStartDateInEpoch || 0;
        popUpEndTime =
          $selectedDate?.dayDefinedEndDateInEpochNotGreaterThanNow || 0;
        if (popUpEndTime - popUpStartTime > 86400000 * 3 + 999) {
          popUpEndTime = popUpStartTime + 86400000 * 3 + 999;
        }
        showPopUp = true;
        break;
      case "SLA Report":
        reportStartTime = defaultStartDate;
        reportEndTime = defaultEndDate;
        reportToBeShown = selectedReport;

        sessionStorage.setItem("report-to-be-shown", reportToBeShown);
        sessionStorage.setItem("report-selected-start-time", reportStartTime);
        sessionStorage.setItem("report-selected-end-time", reportEndTime);
        break;
      default:
        break;
    }
  };

  const checkIfNotGreaterThan3DaysSelected = function (start, end) {
    let error = {
      status: false,
    };
    if (end * 1000 - start * 1000 > 86400000 * 3 + 999) {
      error = {
        status: true,
        message: "From and To Time can have only atmost 3 days difference",
      };
    }
    return error;
  };

  const setReportGeneratedFlag = function () {
    isReportGenerated.set(reportToBeShown);
  };

  const getPermission = function (currentScreen) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = $availableScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };

  isReportGenerated.set(reportToBeShown);

  const hideReport = function () {
    reportToBeShown = null;
    clearDefaultOptions();
    sessionStorage.removeItem("report-to-be-shown");
    sessionStorage.removeItem("report-default-selected-options");
    sessionStorage.removeItem("report-selected-options");
    sessionStorage.removeItem("report-default-selected-options");
  };

  const onBackButtonClick = function () {
    hideReport();
    selectedReport = null;
  };

  $: {
    setReportGeneratedFlag(reportToBeShown);
  }

  $: screenPermission = getPermission($currentPage);

  const onSLAReportButtonClick = function () {
    if (selectedReport === "SLA Report" || reportToBeShown === "SLA Report") {
      selectedReport = null;
      hideReport();
    } else {
      selectedReport = "SLA Report";
      sessionStorage.removeItem("report-selected-options");
      sessionStorage.removeItem("report-default-selected-options");
      closeAllDropDowns();
      hideReport();
    }
  };

  const getLeafNodes = function (hierarchy) {
    selectedNodeList = [];
    defaultSeletionNodeList = [];

    hierarchy.forEach((node) => {
      if (node && node.children.length) {
        getLeafNodes(node.children);
      } else if (node) {
        selectedNodeList.push({ ...node });
      }
    });
  };
</script>

<div class="page-container">
  <div class="report-container">
    {#if !$isReportGenerated}
      <div class="report-container-header">
        <div class="report-container-title">Select Report Content</div>
        <div class="report-container-datepicker">
          <DelDatePicker
            customRangesToShow={dateRangesToShow}
            onDateChanged={onDateChange}
            autoApply={true}
            {defaultStartDate}
            {defaultEndDate}
          />
        </div>
        <div class="report-container-button">
          <button
            class="del-reports-submit-button"
            on:click={onSubmitButtonClick}
            disabled={(selectedReport !== "SLA Report" &&
              !selectedOptions?.length) ||
              disableSubmitButton}>Generate Report</button
          >
        </div>
      </div>
      <div class="report-container-body">
        <div class="del-report-item">
          <button
            class={`del-sla-report-button ${
              selectedReport === "SLA Report" ||
              reportToBeShown === "SLA Report"
                ? "selected"
                : ""
            }`}
            on:click={onSLAReportButtonClick}>{"SLA Report"}</button
          >
        </div>
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={eventReportConfig.buttonLabel}
            showSubmitButton={eventReportConfig.showSubmitButton}
            options={eventReportConfig.options}
            onSelectionUpdated={onEventReportOptionsChange}
            bind:isDropDownOpen={isAlarmHistoryDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Alarm History");
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={eventDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Alarm History" ||
                reportToBeShown === "Alarm History")}
            isDisabled={$isReportGenerated}
          />
        </div>
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={rawReportConfig.buttonLabel}
            showSubmitButton={rawReportConfig.showSubmitButton}
            options={rawReportConfig.options}
            onSelectionUpdated={onRawReportOptionsChange}
            bind:isDropDownOpen={isRawReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Raw Report");
                isAlarmHistoryDropDownOpen = false;
              }
            }}
            defaultSelection={eventDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Raw Report" ||
                reportToBeShown === "Raw Report")}
            isDisabled={$isReportGenerated}
          />
        </div>
      </div>
    {/if}

    {#if showPopUp}
      <DelTimePickerPopUp
        defaultStartTime={popUpStartTime}
        defaultEndTime={popUpEndTime}
        onSubmit={onPopUpSubmitClick}
        onCancel={onPopUpCancelClick}
        submitButtonLabel={"Generate Report"}
        extraValidations={selectedReport === "Raw Report"
          ? checkIfNotGreaterThan3DaysSelected
          : null}
        showLatestTimeAsEndTimeForToday={true}
        showDropDown={selectedReport === "Raw Report" ? true : false}
        defaultSelectedOptions={defaultSeletionNodeList}
        dropDownOptions={selectedNodeList}
      />
    {/if}

    {#if reportToBeShown === "Alarm History"}
      <div class="report-container-body-item">
        <DelEventReport
          baseURL={getDomainURL()}
          nodeId={reportSelectNodeId || null}
          nodeName={reportSelectedNodeName || ""}
          startTime={reportStartTime}
          endTime={reportEndTime}
          currentScreen={"Reports"}
          writePermission={screenPermission === "RW"}
          possibleColumns={eventReportConfig.columnsToShow}
          columnDetails={eventReportConfig.columnDetails}
          showBackButton={true}
          {onBackButtonClick}
          possibleAlertCategories={selectedOptions}
          showButtonForIgnore={eventReportConfig.showButtonForIgnore}
        />
      </div>
    {:else if reportToBeShown === "SLA Report"}
      <DelSLAReport
        baseURL={getDomainURL()}
        currentScreen={"Reports"}
        nodeDetails={$selectedNode}
        defaultStartDate={reportStartTime}
        defaultEndDate={reportEndTime}
        {onBackButtonClick}
      />
    {:else if reportToBeShown === "Raw Report"}
      <DelRawReport
        {nodesList}
        baseURL={getDomainURL()}
        startTime={reportStartTime}
        endTime={reportEndTime}
        currentScreen={"Reports"}
        dateTimeFormat={rawReportConfig.dateTimeFormat}
        columnDetails={rawReportConfig.columnDetails}
        entriesPerPage={rawReportConfig.entriesPerPage}
        showBackButton={true}
        {onBackButtonClick}
        reportTitle={rawReportConfig.reportTitle}
        groupBy={rawReportConfig.groupBy}
        operation={rawReportConfig.operation}
        nodeName={reportSelectedNodeName || ""}
      />
    {/if}
  </div>
</div>

<style>
  .page-container {
    background-color: #f7f8fa;
    height: 100%;
    padding: 20px;
  }

  .report-container {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
  }

  .report-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
  }

  .report-container-title {
    text-align: left;
    font: normal normal 700 18px/24px Roboto;
    letter-spacing: 0;
    color: #2f30b5;
    opacity: 1;
  }

  .report-container-button {
    display: flex;
    align-items: center;
  }

  .del-reports-submit-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-reports-submit-button:enabled:active {
    border: 1px solid #79a4e9;
  }

  .del-reports-submit-button:enabled:hover {
    border-color: #79a4e9;
  }

  .del-reports-submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .report-container-body {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 22px;
    flex-wrap: wrap;
  }

  .report-container-body .del-report-item {
    flex: 0 0 auto;
  }

  .del-sla-report-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-sla-report-button:enabled:active {
    border: 1px solid #79a4e9;
  }

  .del-sla-report-button:enabled:hover {
    border-color: #79a4e9;
  }

  .del-sla-report-button:disabled,
  .del-sla-report-button:disabled:hover {
    cursor: not-allowed;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eee;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #eee;
    /* opacity: 0.8; */
  }

  .del-sla-report-button.selected {
    border: none;
    background-color: #79a4e9;
    color: white;
  }
</style>
