<script>
  export let data;
  export let selectedNode = null;
  export let path = "";
  export let unClickableNodeTypes = [];
  export let setLeafNodeAsClickable = true;
  export let classNameForRootNode = "";
  export let nodesInPath = [];
  export let showNavigationTree = false;

  $: {
    if (unClickableNodeTypes?.length) checkIfSelectedNodeIsUnclickable();
  }

  function checkIfSelectedNodeIsUnclickable(node = selectedNode, assignNode) {
    if (unClickableNodeTypes.includes(node?.type)) {
      if (node?.children?.length) {
        checkIfSelectedNodeIsUnclickable(node.children[0], true);
      } else if (setLeafNodeAsClickable && assignNode) {
        selectedNode = node;
      } else if (!setLeafNodeAsClickable) {
        selectedNode = null;
      }
    } else if (assignNode) {
      selectedNode = node;
    }
  }

  function handleNodeClick(item) {
    if (selectedNode?.id === item?.id) {
      return;
    }
    selectedNode = item;
    showNavigationTree = false;
  }

  function checkIfNodeIsClickable(type, childrenLength) {
    if (unClickableNodeTypes.includes(type)) {
      if (childrenLength) {
        return false;
      } else if (!setLeafNodeAsClickable) {
        return false;
      }
    }
    return true;
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class={`${classNameForRootNode} children-nodes-list`}>
  {#each data as item, index (item.id)}
    <div
      class="node-element"
      class:multi-child-node={item?.children?.length > 1}
    >
      <button
        class:selected={item.id === selectedNode?.id}
        class:last-node={index === data.length - 1}
        class:first-node={index === 0}
        class:non-leaf-node={item?.children?.length}
        bind:this={item.element}
        disabled={unClickableNodeTypes &&
          !checkIfNodeIsClickable(item.type, item?.children?.length)}
        ><div class="node-name-container">
          <div
            class="rectangle"
            on:click={(event) => {
              event.stopPropagation();
              item.showChildren = !item.showChildren;
            }}
          >
            <div class="circle" />
          </div>
          <div on:click={() => handleNodeClick(item)} class="node-name">
            {item.name}
          </div>
        </div>
      </button>
      {#if item.children && item.children.length > 0 && item.showChildren}
        <div class="children">
          <svelte:self
            data={item.children}
            bind:selectedNode
            bind:showNavigationTree
            path={path ? path + " > " + item.name : item.name}
            nodesInPath={nodesInPath.concat([
              {
                id: item.id,
                name: item.name,
                type: item.type,
              },
            ])}
            {setLeafNodeAsClickable}
            {unClickableNodeTypes}
          />
        </div>
      {/if}
    </div>
  {/each}
</div>

<style>
  .node-element button {
    border: none;
    background-color: transparent;
    padding: 0 5px;
    line-height: 20px;
    font: normal normal normal 14px Roboto;
    color: #000000;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .node-element button .node-name {
    cursor: pointer;
  }
  .node-element .last-node {
    border-left: none;
  }
  .node-element .non-leaf-node {
    color: #2f30b5;
  }
  .node-element .selected {
    color: #ff7a19;
    font-weight: bold;
  }
  .node-element button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .node-name-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .children {
    margin-left: 23px;
  }
  .circle {
    height: 6px;
    width: 6px;
    background-color: #2f30b5;
    border-radius: 50%;
    vertical-align: middle;
  }

  .non-leaf-node .circle {
    height: 4px;
    width: 4px;
  }

  .non-leaf-node .rectangle {
    height: 8px;
    width: 8px;
    border: 1px solid #2f30b5;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .multi-child-node {
    border-left: 1px solid #2f30b5;
  }

  .node-element
    :has(.node-element.multi-child-node button.non-leaf-node)
    button:not(.last-node).non-leaf-node:before,
  .node-element:not(:last-child)
    :has(.node-element.multi-child-node button.non-leaf-node)
    button.non-leaf-node:before {
    border-left: 1px solid #2f30b5;
    left: -6px;
  }

  .node-element button:before {
    position: relative;
    top: -14px;
    height: 2em;
    width: 17px;
    color: white;
    border-bottom: 1px solid #2f30b5;
    border-left: 1px solid #2f30b5;
    content: "";
    left: -5px;
    margin-right: -5px;
  }

  .multi-child-node > button:before {
    border-left: none;
  }

  :global(
      .multi-child-node
        > .children
        > .children-nodes-list
        > .node-element:nth-last-child(2):has(> button.non-leaf-node)
    ) {
    border-left: 1px solid #2f30b5;
  }

  :global(
      .multi-child-node
        > .children
        > .children-nodes-list
        > .node-element:nth-last-child(2)
        > button.non-leaf-node:before
    ) {
    border-left: none;
  }
  .root-node > .node-element > button:before {
    border: none;
  }
</style>
