<script>
  import { onMount, onDestroy } from "svelte";
  import { APP_VERSION } from "./../../config.js";
  import {  applicationName } from "./../stores.js";

  let showAboutDropDown = false;
  let aboutButtonElement = null;

  const closeDropDown = function (event) {
    if (showAboutDropDown && event.target !== aboutButtonElement) {
      showAboutDropDown = false;
    }
  };

  onMount(() => {
    window.addEventListener("click", closeDropDown);
  });

  onDestroy(() => {
    window.removeEventListener("click", closeDropDown);
  });

  const onClickAboutButton = function () {
    showAboutDropDown = !showAboutDropDown;
  };
</script>

<div>
  <button
    class="del-nav-item del-button {showAboutDropDown ? 'active' : ''}"
    id="about-button"
    on:click={onClickAboutButton}
    bind:this={aboutButtonElement}>About</button
  >
  {#if showAboutDropDown}
    <div class="del-about-container">
      <div class="del-about-dropdown-container">
        <div class="del-about-dropdown-inner-container">
          <div class="del-about-item">
            <div class="del-about-item-name">Application Name</div>
            <div class="del-about-item-value">{$applicationName}</div>
          </div>
          <div class="del-about-item">
            <div class="del-about-item-name">Application Version</div>
            <div class="del-about-item-value">{APP_VERSION}</div>
          </div>
        </div>

        <footer class="del-about-footer">
          Licensed by Gadgeon Systems Inc. All rights reserved
        </footer>
      </div>
    </div>
  {/if}
</div>

<style>
  .del-about-container {
    position: relative;
  }
  #about-button {
    vertical-align: top;
  }
  #about-button.active {
    color: #1f419b;
    font-weight: 600;
  }
  #about-button:hover {
    color: #1f419b;
  }
  .del-about-dropdown-container {
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    width: 300px;
    min-height: 50px;
  }
  .del-about-dropdown-inner-container {
    padding: 15px;
  }

  .del-about-item {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 50% auto;
  }

  .del-about-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .del-about-item-name,
  .del-about-item-value {
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0;
    color: #778388;
    opacity: 1;
  }

  .del-about-item-value {
    color: #000;
  }

  .del-about-item-value::before {
    content: " : ";
    padding-right: 15px;
  }
  .del-about-footer {
    text-align: center;
    background: #a4aeb2 0 0 no-repeat padding-box;
    height: 15px;
    color: #fff;
    width: 100%;
    font: normal normal normal 12px Roboto;
    border-radius: 0 0 5px 5px;
    margin: 0;
    padding: 5px 0;
  }
</style>
