<script>
  import Icon from "@iconify/svelte";
  import moment from "moment";
  import Loader from "./Loader.svelte";
  import { exportExcel, generatePDF, exportCsv } from "./exportReports.js";

  export let nodesList = null;
  export let startTime = null;
  export let endTime = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let dateTimeFormat = "MMMM DD YYYY HH:mm:ss";
  export let columnDetails = [];
  export let entriesPerPage = 10;
  export let showBackButton = true;
  export let onBackButtonClick = null;
  export let reportTitle = "Raw Report";
  export let groupBy = "none";
  export let operation = "avg";
  export let nodeName = "";

  let paginatedData = [];
  let currentPage = 1;
  let totalPages = 0;
  let isAwaitingReportDataAPI = false;
  let formattedTimeStamps = [];
  let nodeParameterMap = {};
  let parametersMap = {};
  let showNoParametersMessage = false;
  let payloadParameters = {};
  let dateColumnData = [];
  let nodesToShow = [];
  let tableHeaderData = [];
  let tableContent = [];
  let showNoDataMessage = false;

  $: columnsOrder = columnDetails.map((item) => item.columnTitle);

  $: {
    if (
      nodesList?.length &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      columnDetails?.length &&
      groupBy &&
      operation
    ) {
      invokeGetReportDataAPI();
    }
  }

  $: {
    totalPages = Math.ceil(dateColumnData.length / entriesPerPage);
  }

  async function getResponse(endpoint, payload, method = "POST") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const filterParameters = function (node) {
    if (node) {
      nodeParameterMap[node.name] = [];
      for (const item of columnDetails) {
        switch (item.parameterType) {
          case "Basic":
            if (node.parameters && node.parameters.length) {
              for (const parameter of node.parameters) {
                if (
                  item.parameterCategories.includes(
                    parameter.ParameterCategory
                  ) &&
                  item.deviceCategories.includes(parameter.DeviceCategory)
                ) {
                  nodeParameterMap[node.name].push(parameter.ParameterID);
                  const parameterName =
                    parameter.ParameterAliasName ||
                    parameter.ParameterFriendlyName;
                  parametersMap[parameter.ParameterID] = {
                    columnTitle: `${item.columnTitle}${
                      item.unit ? ` ${item.unit}` : ""
                    }`,
                    colHeading: `${parameterName}${
                      item.unit ? ` ${item.unit}` : ""
                    }`,
                    parameterName,
                    nodeName: node.name,
                    nodeId: node.id,
                  };
                  payloadParameters.basicParameters.push(parameter.ParameterID);
                }
              }
            }
            break;
          case "Derived":
            if (node.derivedparameters && node.derivedparameters.length) {
              for (const parameter of node.derivedparameters) {
                if (
                  item.parameterCategories.includes(
                    parameter.ParameterCategory
                  ) &&
                  item.deviceCategories.includes(parameter.DeviceCategory)
                ) {
                  nodeParameterMap[node.name].push(
                    parameter.DerivedParameterId
                  );
                  const parameterName =
                    parameter.ParameterAliasName ||
                    parameter.DerivedParameterName;
                  parametersMap[parameter.DerivedParameterId] = {
                    columnTitle: `${item.columnTitle}${
                      item.unit ? ` ${item.unit}` : ""
                    }`,
                    colHeading: `${parameterName}${
                      item.unit ? ` ${item.unit}` : ""
                    }`,
                    parameterName,
                    nodeName: node.name,
                    nodeId: node.id,
                  };
                  payloadParameters.derivedParameters.push(
                    parameter.DerivedParameterId
                  );
                }
              }
            }
            break;
          default:
            console.warn(
              `Unidentified parameter type ${item.parameterType}, it should either be Basic or Derived!`
            );
            break;
        }
      }
    }
  };

  const getChildNodes = function (hierarchy) {
    hierarchy.forEach((node) => {
      filterParameters(node);
      if (node.children.length) {
        getChildNodes(node.children);
      }
    });
  };

  const getPayloadForReportDataAPI = function (details) {
    payloadParameters = {
      derivedParameters: [],
      basicParameters: [],
    };
    formattedTimeStamps = [];
    nodeParameterMap = {};
    parametersMap = {};
    getChildNodes(nodesList);
    if (
      !(
        payloadParameters.basicParameters.length ||
        payloadParameters.derivedParameters.length
      )
    ) {
      showNoParametersMessage = true;
      return false;
    }
    return {
      derivedParameters: payloadParameters.derivedParameters,
      basicParameters: payloadParameters.basicParameters,
      starttime: startTime,
      endtime: endTime,
      operation: operation,
      groupby: groupBy,
    };
  };

  const invokeGetReportDataAPI = function () {
    if (
      typeof localStorage === "undefined" ||
      !(
        nodesList?.length &&
        startTime &&
        endTime &&
        currentScreen &&
        baseURL &&
        columnDetails?.length &&
        groupBy &&
        operation
      )
    ) {
      return;
    }
    dateColumnData = [];
    tableContent = [];
    tableHeaderData = [];
    nodesToShow = [];
    showNoParametersMessage = false;
    showNoDataMessage = false;

    const payload = getPayloadForReportDataAPI(columnDetails);

    if (!payload) return;

    const endpoint = `parameters/telemetry/rawdata/all`;
    isAwaitingReportDataAPI = true;
    getResponse(endpoint, payload)
      .then((data) => {
        isAwaitingReportDataAPI = false;
        onReportDataReceived(data);
      })
      .catch((error) => {
        isAwaitingReportDataAPI = false;
        showNoDataMessage = true;
        console.error("API call failed", error);
      });
  };

  const formatTimeStamp = function (timestamp) {
    if (!timestamp) return "";
    const format = dateTimeFormat || "DD-MMM-YYYY HH:mm:ss";
    return moment(new Date(timestamp * 1000)).format(format);
  };

  const checkValue = function (value) {
    if (value === undefined || value === null || isNaN(value)) return "";
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const getExportData = function () {
    let exportData = {};
    for (const node of nodesToShow) {
      let tableContent = [];
      exportData[node] = [];
      for (const parameter of nodeParameterMap[node]) {
        tableContent.push(parametersMap[parameter]);
      }

      for (const timestamp of formattedTimeStamps) {
        let row = {
          Data: timestamp,
        };
        for (const column of tableContent) {
          row[column.columnTitle] = column.readings[timestamp];
        }
        exportData[node].push(row);
      }
    }
    return exportData;
  };

  const getTableContent = function () {
    const tableContent = [];
    for (const node of nodesToShow) {
      for (const parameter of nodeParameterMap[node]) {
        tableContent.push(parametersMap[parameter]);
      }
    }
    return tableContent;
  };

  const onReportDataReceived = function (data) {
    isAwaitingReportDataAPI = false;
    if (data) {
      if (data) {
        formattedTimeStamps = data.map((item) =>
          formatTimeStamp(item.unixtime)
        );
        for (const parameterId of Object.keys(parametersMap)) {
          parametersMap[parameterId].readings = {};
          for (const item of data) {
            const formattedTimeStamp = formatTimeStamp(item.unixtime);
            let reading = "";
            if (item.parameters) {
              const match = item.parameters.find(
                (parameter) => parameter.parameterId === parameterId
              );
              if (match) {
                reading = checkValue(match.reading);
              }
            }
            parametersMap[parameterId].readings[formattedTimeStamp] = reading;
          }
        }
        dateColumnData = [...formattedTimeStamps];
        nodesToShow = Object.keys(nodeParameterMap).filter(
          (node) => nodeParameterMap[node].length
        );
        let _tableHeaderData = [];
        for (const node of nodesToShow) {
          for (const parameter of nodeParameterMap[node]) {
            _tableHeaderData.push(parametersMap[parameter]);
          }
        }
        tableHeaderData = [..._tableHeaderData];
        tableContent = getTableContent();
        if (!dateColumnData?.length) {
          showNoDataMessage = true;
        }
      }
      updatePagination();
    }
  };

  const formatDateTime = function (value, format) {
    const dateFormat = format ? format : dateTimeFormat;
    return !value || isNaN(value)
      ? ""
      : moment(new Date(parseInt(value * 1000))).format(dateFormat);
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = dateColumnData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const getCSVExportData = function () {
    const columnData = getTableContent();
    let exportData = [];
    if (formattedTimeStamps && formattedTimeStamps.length) {
      for (const timestamp of formattedTimeStamps) {
        let row = {
          Date: timestamp,
        };
        for (const column of columnData) {
          row[`${column.columnTitle} - ${column.nodeName}`] =
            column.readings[timestamp];
        }
        exportData.push(row);
      }
    }
    return exportData;
  };

  const handleReportExport = function (exportType) {
    const startTimeFormatted = formatDateTime(startTime);
    const endTimeFormatted = formatDateTime(endTime);

    if (exportType === "pdf") {
      const reportDataForDownload = getExportData();
      generatePDF(
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportDataForDownload,
        reportTitle,
        columnsOrder.length
      );
    } else if (exportType === "xls") {
      const reportDataForDownload = getExportData();
      exportExcel(
        exportType,
        `Raw Report of ${nodeName}`,
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportDataForDownload,
        reportTitle
      );
    } else {
      const reportDataForDownload = getCSVExportData();
      exportCsv(
        exportType,
        `Raw Report of ${nodeName}`,
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportDataForDownload,
        reportTitle
      );
    }
  };
</script>

<div class="del-report-container">
  {#if isAwaitingReportDataAPI}
    <Loader />
  {/if}
  <div class="del-report-header-container">
    <div
      class="del-report-header-inner-container del-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-report-header-main-title">{reportTitle}</div>
          <div class="del-report-header-sub-title">Report of {nodeName}</div>
        </div>
      </div>
      <div class="del-event-report-header-right-container">
        <div>
          <div class="del-report-header-titles">Download</div>
          <div class="flex-container del-report-table-export-buttons-container">
            <button
              name="csvDownloadButton"
              title={dateColumnData?.length
                ? "Export CSV"
                : "No data to download"}
              class="del-report-table-export-button csv-export-button"
              on:click={handleCSVExportButtonClick}
              disabled={!dateColumnData?.length}
            />
            <button
              name="excelDownloadButton"
              title={dateColumnData?.length
                ? "Export Excel"
                : "No data to download"}
              class="del-report-table-export-button excel-export-button"
              on:click={handleExcelExportButtonClick}
              disabled={!dateColumnData?.length}
            />
            <button
              name="pdfDownloadButton"
              title={dateColumnData?.length
                ? "Export PDF"
                : "No data to download"}
              class="del-report-table-export-button pdf-export-button"
              on:click={handlePDFExportButtonClick}
              disabled={!dateColumnData?.length}
            />
          </div>
        </div>
        <div>
          <div class="del-report-header-titles del-report-summary-title">
            Report Summary
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`From : ${formatDateTime(startTime)}`}
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`To : ${formatDateTime(endTime)}`}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="del-report-table-container">
    <!-- Report Table -->
    {#if dateColumnData?.length}
      <div class="del-raw-report-table-inner-container">
        <table class="del-raw-report-table">
          <thead>
            {#if columnsOrder?.length}
              <tr class="del-raw-report-table-header-row">
                <th
                  class="del-raw-report-table-cell del-raw-report-table-header-cell del-raw-report-table-first-column del-raw-report-table-header-cell-primary del-raw-report-table-header-cell-primary-first"
                  rowspan={"2"}>Date</th
                >
                {#each nodesToShow as node (node)}
                  <th
                    class="del-raw-report-table-cell del-raw-report-table-header-cell del-raw-report-table-header-cell-primary"
                    colspan={nodeParameterMap[node].length}>{node}</th
                  >
                {/each}
              </tr>
              <tr class="del-raw-report-table-header-row">
                {#each tableHeaderData as item}
                  <th
                    class="del-raw-report-table-cell del-raw-report-table-header-cell del-raw-report-table-header-cell-secondary"
                    >{item.columnTitle}</th
                  >
                {/each}
              </tr>
            {/if}
          </thead>
          <tbody class="table-body">
            {#each paginatedData as timestamp}
              <tr class="del-raw-report-table-row">
                <td
                  class="del-raw-report-table-cell del-raw-report-table-first-column"
                  >{timestamp}</td
                >
                {#each tableContent as column}
                  <td class="del-raw-report-table-cell"
                    >{column.readings[timestamp] ?? ""}</td
                  >
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {:else if showNoParametersMessage}
      <div class="no-data-message">No Matching Parameters Found</div>
    {:else if showNoDataMessage}
      <div class="no-data-message">No Data Available</div>
    {/if}
    <!-- Pagination -->
    {#if dateColumnData.length > entriesPerPage}
      <div class="pagination-container">
        <div class="entries-display">
          {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
            currentPage * entriesPerPage,
            dateColumnData.length
          )} of ${dateColumnData.length} entries`}
        </div>
        <div class="pagination-buttons">
          <button
            class="first-page"
            disabled={currentPage === 1}
            on:click={handleFirstPageClick}>First</button
          >
          <button
            class="previous-page"
            disabled={currentPage === 1}
            on:click={handlePreviousPageClick}>Previous</button
          >
          <span class="current-page"
            >{`Page ${currentPage} of ${totalPages}`}</span
          >
          <button
            class="next-page"
            disabled={currentPage === totalPages}
            on:click={handleNextPageClick}>Next</button
          >
          <button
            class="last-page"
            disabled={currentPage === totalPages}
            on:click={handleLastPageClick}>Last</button
          >
        </div>
      </div>
    {/if}
  </div>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-report-container {
    position: relative;
  }
  .del-report-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    margin: 20px;
    padding: 10px;
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0 20px;
    margin-top: 20px;
  }

  .del-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./images/headerBackground.svg");
  }

  .del-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-report-header-back-button:enabled:hover,
  .del-report-header-back-button:enabled:active,
  .del-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-small-titles {
    font-size: 14px;
  }

  .del-report-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-report-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-report-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-report-table-export-button:enabled:hover,
  .del-report-table-export-button:enabled:active,
  .del-report-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .del-report-summary-title {
    margin-bottom: 10px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .del-report-header-dates {
    text-align: right;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  .del-event-report-header-right-container {
    display: flex;
    gap: 80px;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  /* Table styles */

  .del-raw-report-table-inner-container {
    margin: 0;
    padding: 0;
    overflow-x: auto;
  }

  .del-raw-report-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-raw-report-table td:first-child,
  .del-raw-report-table .del-raw-report-table-header-cell-primary-first {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    min-width: 200px;
  }

  .del-raw-report-table-header-cell-primary {
    border-bottom: 1px solid #aaaeb4;
  }

  .del-raw-report-table-header-cell-primary-first {
    border-bottom: none;
  }

  .del-raw-report-table th:first-child {
    border-radius: 6px 0 0 6px;
  }

  .del-raw-report-table th:last-child {
    border-radius: 0 6px 0 0;
  }

  .del-raw-report-table th:not(:last-child) {
    border-right: 1px solid #aaaeb4;
  }

  .del-raw-report-table-cell:not(:last-child) {
    border-right: 1px solid #aaaeb4;
  }

  .del-raw-report-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #636c7b;
    min-width: 250px;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-raw-report-table-row:not(:last-child) .del-raw-report-table-cell {
    border-bottom: 1px solid #aaaeb4;
  }

  .del-raw-report-table-header-row {
    text-align: center;
    font: normal normal medium 16px/21px Roboto;
    color: #292727;
    background: #f2f4f5 0 0 no-repeat padding-box;
    border-radius: 5px;
  }

  .del-raw-report-table-header-cell {
    text-align: center;
    font: normal normal medium 16px/21px Roboto;
    color: #292727;
    background: #f2f4f5 0 0 no-repeat padding-box;
  }

  .del-raw-report-table
    th.del-raw-report-table-header-cell-secondary:first-child {
    border-radius: 0;
  }

  .del-raw-report-table
    th.del-raw-report-table-header-cell-secondary:last-child {
    border-radius: 0 0 6px 0;
  }

  .del-raw-report-table-row:not(:last-child) {
    border-bottom: 1px solid #aaaeb4;
  }

  /* End of table styles */

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */
</style>
