<script>
  import Icon from "@iconify/svelte";
  import DelPieChart from "./DelPieChart/DelPieChart.svelte";
  import DelDataTable from "./DelDataTable/DelDataTable.svelte";
  import DelDatePicker from "./../DelDatePicker/DelDatePicker.svelte";
  import DelStackedBarChartSla from "./DelStackedBarChartSLA/DelStackedBarChartSLA.svelte";
  import Switch from "./Switch.svelte";
  import moment from "moment";

  export let baseURL;
  export let currentScreen;
  export let nodeDetails;
  export let defaultStartDate;
  export let defaultEndDate;
  export let showBackButton = true;
  export let onBackButtonClick;

  let pdfPayload = {
    EndTime: 0,
    NodeId: "",
    SLACategory: "",
    IncludeIgnored: true,
    StartTime: 0,
  };
  let isIgnoredAlarmsIncluded = true;
  let selectedCategory = "CO2";
  let categoryUnit = "%";
  let startTime;
  let endTime;
  let selectedDate;

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  async function exportPDF() {
    try {
      const url = `${baseURL}/parameters/api/SLAReport/pdf`;

      pdfPayload = {
        EndTime: endTime,
        NodeId: nodeDetails?.id,
        SLACategory: selectedCategory,
        IncludeIgnored: isIgnoredAlarmsIncluded,
        StartTime: startTime,
      };

      if (
        !(
          pdfPayload.StartTime &&
          pdfPayload.EndTime &&
          pdfPayload.NodeId &&
          pdfPayload.SLACategory &&
          pdfPayload.IncludeIgnored
        )
      )
        return;

      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(pdfPayload),
      };

      const response = await fetch(url, options);
      exportPDFOnSuccess(response);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function exportPDFOnSuccess(response) {
    let content = await response.blob();

    const disposition = response.headers.get("content-disposition");
    let filename = null;
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    let blob = new Blob([content]);

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = data;
    link.download = filename ? filename : "Template.pdf";
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  function onDateChange(date) {
    if (date) {
      startTime = date.dayDefinedStartDateInSeconds;
      endTime = date.dayDefinedEndDateInSecondsNotGreaterThanNow;
      selectedDate = date.date;
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="sla-report-container">
  <div class="sla-header-container">
    {#if showBackButton}
      <div class="back-button-div">
        <button
          class="del-header-back-button"
          on:click={() => {
            onBackButtonClick && onBackButtonClick();
          }}><Icon icon="ep:back" /></button
        >
      </div>{/if}
    <div class="date-picker-div">
      <DelDatePicker
        onDateChanged={onDateChange}
        {defaultStartDate}
        {defaultEndDate}
        autoApply={true}
        format={"DD-MM-YYYY"}
        firstDay={1}
        customRangesToShow={[
          "Today",
          "Yesterday",
          "Last 7 days",
          "Last 30 days",
          "Current Week",
          "Last Week",
          "Current Month",
          "Last Month",
        ]}
      />
    </div>
    <div class="tab-div">
      <button
        class={`category-selector-button ${
          selectedCategory === "CO2" ? "selected" : ""
        }`}
        on:click={() => {
          selectedCategory = "CO2";
          categoryUnit = "PPM";
        }}>CO2</button
      >
      <button
        class={`category-selector-button ${
          selectedCategory === "Temperature" ? "selected" : ""
        }`}
        on:click={() => {
          selectedCategory = "Temperature";
          categoryUnit = "°C";
        }}>Temperature</button
      >
      <button
        class={`category-selector-button ${
          selectedCategory === "Humidity" ? "selected" : ""
        }`}
        on:click={() => {
          selectedCategory = "Humidity";
          categoryUnit = "%";
        }}>Humidity</button
      >
    </div>
    <div class="toggle-button-div">
      Include Ignored Alarms
      <Switch bind:checked={isIgnoredAlarmsIncluded} />
    </div>
    <div class="download-button-div">
      <button class="download-button" on:click={exportPDF}
        >Download Report</button
      >
    </div>
  </div>
  <div class="sla-body-container">
    <div class="body-row">
      <div class="pie-chart-container">
        <div class="title-div">Selected Range Data</div>
        <DelPieChart
          {baseURL}
          {currentScreen}
          {nodeDetails}
          {startTime}
          {endTime}
          IncludeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory}
          showLegend={true}
          custom={false}
          chartHeight={"350px"}
          sortOrder={["Normal", "Informational", "Warning", "Critical"]}
          selectColors={{
            Normal: "#59A047",
            Informational: "#92D050",
            Warning: "#F8C506",
            Critical: "#E64D36",
          }}
        />
      </div>
      <div class="data-table-container">
        <div class="title-div">Top Alarm Values</div>
        <DelDataTable
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory}
          dataSelection={"topten"}
          columnTitles={["DATE/HOUR", "VALUE (°C)"]}
        />
      </div>
      <div class="data-table-container">
        <div class="title-div">Low Alarm Values</div>
        <DelDataTable
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory}
          dataSelection={"lowestten"}
          columnTitles={["DATE/HOUR", "VALUE (°C)"]}
        />
      </div>
      <div class="pie-chart-container">
        <div class="title-div">Selected Range Data (7AM to 7PM)</div>
        <DelPieChart
          {baseURL}
          {currentScreen}
          {nodeDetails}
          {startTime}
          {endTime}
          IncludeIgnored={isIgnoredAlarmsIncluded}
          slaCategory={selectedCategory}
          showLegend={true}
          custom={true}
          chartHeight={"350px"}
          sortOrder={["Normal", "Informational", "Warning", "Critical"]}
          selectColors={{
            Normal: "#59A047",
            Informational: "#92D050",
            Warning: "#F8C506",
            Critical: "#E64D36",
          }}
        />
      </div>
    </div>
    <div class="body-graph-row">
      <div class="stacked-chart-div">
        <div class="title-div">Air Quality Per Month</div>
        <DelStackedBarChartSla
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          endPoint={"barchart"}
          slaCategory={selectedCategory}
          alertCategoryOrder={[
            "Normal",
            "Informational",
            "Warning",
            "Critical",
          ]}
          unit={categoryUnit}
          xAxisFormat={"MMM 'YY"}
          chartHeight={"350px"}
          chartColors={["#109E46", "#92D050", "#FDC500", "#FA3838"]}
          legendPosition={"bottom"}
        />
      </div>
    </div>
    <div class="body-graph-row">
      <div class="stacked-chart-div">
        <div class="title-div">
          Hourly Report of {moment(selectedDate).format("DD MMM YYYY")}
        </div>
        <DelStackedBarChartSla
          {baseURL}
          {currentScreen}
          nodeId={nodeDetails?.id}
          {startTime}
          {endTime}
          includeIgnored={isIgnoredAlarmsIncluded}
          endPoint={"hourly"}
          slaCategory={selectedCategory}
          alertCategoryOrder={[
            "Normal",
            "Informational",
            "Warning",
            "Critical",
          ]}
          unit={categoryUnit}
          xAxisFormat={"hh a"}
          chartHeight={"350px"}
          chartColors={["#109E46", "#92D050", "#FDC500", "#FA3838"]}
          legendPosition={"bottom"}
        />
      </div>
    </div>
  </div>
</div>

<style>
  .sla-report-container {
    background-color: #f7f8fa;
  }

  .sla-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .tab-div {
    display: flex;
    gap: 10px;
  }

  .category-selector-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
    height: 30px;
  }

  .category-selector-button.selected {
    background-color: #f37a25;
    border: 1px solid #f37a25;
    color: white;
  }

  .category-selector-button:hover {
    background-color: #d5d3d3;
  }

  .selected:hover {
    background-color: #f37a25;
  }

  .toggle-button-div {
    font-weight: 600 !important;
    font-size: 12px;
    color: #504f4f;
    font-family: "Roboto", sans-serif;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .download-button {
    cursor: pointer;
    border: 1px solid #f37a25;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
    height: 30px;
  }

  .download-button:hover {
    background-color: #d5d3d3;
  }

  .body-row {
    display: flex;
    justify-content: space-around;
    background-color: white;
    box-shadow: 5px 1px 7px #00000014;
    padding: 10px;
    margin: 10px;
    min-height: 410px;
  }

  .body-graph-row {
    background-color: white;
    box-shadow: 5px 1px 7px #00000014;
    padding: 10px;
    margin: 10px;
    min-height: 410px;
  }

  .title-div {
    color: #504f4f !important;
    font-weight: 700 !important;
    margin-bottom: 5px !important;
    padding: 0 !important;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  .back-button-div {
    height: 40px;
  }

  .del-header-back-button {
    background: #f37a25 0 0 no-repeat padding-box;
    border: 1px solid #f37b25ae;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-header-back-button:enabled:hover,
  .del-header-back-button:enabled:active,
  .del-header-back-button:enabled:focus {
    transform: scale(1.05);
    cursor: pointer;
  }
</style>
