<script>
  import moment from "moment";
  import {
    selectedNode,
    selectedDate,
    parentNodeType,
    childNodeType,
    defaultNodeId,
    allocatedScreenNames,
  } from "../stores";
  import { getDomainURL, invokeAPI } from "../invokeAPI";
  import { ENVIRONMENT } from "../../environment";
  import { isSameAsToday } from "./../utilityFunctions";
  import {
    pathNameSSPA,
    screensToShow,
    co2RealTimeLabel,
    tempRealTimeLabel,
    humidityRealTimeLabel,
    batteryRealTimeLabel,
    trendConfiguration,
  } from "./../../config";
  import DelProfileCard from "./../components/DelProfileCard/DelProfileCard.svelte";
  import DelRealtimeParameterCard from "./../components/DelRealtimeParameterCard/DelRealtimeParameterCard.svelte";
  import DelAlertCount from "./../components/DelAlertCount/DelAlertCount.svelte";
  import DelMultiAxisChart from "./../components/DelMultiAxisChart/DelMultiAxisChart.svelte";

  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;

  let previousStartTime = null;
  let previousEndTime = null;

  $: {
    if ($selectedDate) {
      const start = $selectedDate?.dayDefinedStartDateInSeconds;
      let end = $selectedDate?.dayDefinedEndDateInSeconds;
      const endNotGreaterThanNow =
        $selectedDate?.dayDefinedEndDateInSecondsNotGreaterThanNow;
      const duration = end - start;
      previousStartTime = start - duration;
      if (isSameAsToday($selectedDate?.endDateInEpoch)) {
        previousEndTime = endNotGreaterThanNow - duration;
      } else {
        previousEndTime = end - duration;
      }
    }
  }
  $: nodeName = $selectedNode?.name;
  $: selectedNodeId = $selectedNode?.id;
  $: parentNode = $selectedNode?.nodesInPath[0];

  let selectedAlarmArea = "All Alarms";
  let updatedTime = -Infinity;

  const navigateToEventsPage = function (category) {
    if (selectedAlarmArea === "All Alarms")
      sessionStorage.setItem("saved-node-id", parentNode?.id);

    let filters = {
      currentstatus: ["Active"],
    };
    if (category) {
      filters.category = [category];
    }
    sessionStorage.setItem(
      "eventViewerSelectedFilters",
      JSON.stringify(filters)
    );
    window.location.href = `${pathPrefix}/AlarmViewer`;
  };

  function setHighestTimestamp(value) {
    if (!isNaN(updatedTime)) {
      updatedTime = Math.max(updatedTime, value);
    }
  }
</script>

<div class="del-page-container">
  <div class="del-left-container">
    <div class="left-profile-container">
      <DelProfileCard
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        selectedNode={parentNode}
        displayImage={true}
      />
    </div>
    <div class="widget-container alarm-widget-div">
      <div class="alarm-tab-div">
        <button
          class={`alarm-tab-button ${
            selectedAlarmArea === "All Alarms" ? "selected" : ""
          }`}
          on:click={() => {
            selectedAlarmArea = "All Alarms";
          }}
        >
          All Alarms
        </button>
        <button
          class={`alarm-tab-button ${
            selectedAlarmArea === "Room Alarms" ? "selected" : ""
          }`}
          on:click={() => {
            selectedAlarmArea = "Room Alarms";
          }}
        >
          Room Alarms
        </button>
      </div>
      {#if selectedAlarmArea === "All Alarms"}
        <DelAlertCount
          baseURL={getDomainURL()}
          isRealTime={true}
          selectedNodeDetails={parentNode}
          currentNodeId={parentNode?.id}
          uniqueId={"parentAlertCount"}
          title={""}
          viewAllClicked={navigateToEventsPage}
          categoryClicked={navigateToEventsPage}
          isViewAll={$allocatedScreenNames?.includes(
            screensToShow["/Reports"]?.screen
          )}
        />
      {:else}
        <DelAlertCount
          baseURL={getDomainURL()}
          isRealTime={true}
          selectedNodeDetails={$selectedNode}
          currentNodeId={$selectedNode?.id}
          title={""}
          uniqueId={"childAlertCount"}
          viewAllClicked={navigateToEventsPage}
          categoryClicked={navigateToEventsPage}
          isViewAll={$allocatedScreenNames?.includes(
            screensToShow["/AlarmViewer"]?.screen
          )}
        />
      {/if}
    </div>
  </div>
  <div class="del-right-container">
    <div class="del-top-right-container">
      <div class="right-profile-container">
        <DelProfileCard
          baseURL={getDomainURL()}
          currentScreen={"Dashboard"}
          selectedNode={$selectedNode}
          displayImage={false}
        />
      </div>
      <div class="realtime-parameter-container">
        <div class="realtime-parameter-header">
          <div class="widget-title">CO2</div>
          <div class="realtime-latest-time">
            {moment(updatedTime * 1000).format("DD-MMM-YYYY hh:mm:ss")}
          </div>
        </div>
        <div class="parameter-card-container">
          <div class="co2-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={co2RealTimeLabel.parameterType}
              parameterCategory={co2RealTimeLabel.parameterCategory}
              deviceCategory={co2RealTimeLabel.deviceCategory}
              readingTitle={co2RealTimeLabel.readingTitle}
              readingUnit={co2RealTimeLabel.readingUnit}
              uniqueID={co2RealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="temp-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={tempRealTimeLabel.parameterType}
              parameterCategory={tempRealTimeLabel.parameterCategory}
              deviceCategory={tempRealTimeLabel.deviceCategory}
              readingTitle={tempRealTimeLabel.readingTitle}
              readingUnit={tempRealTimeLabel.readingUnit}
              uniqueID={tempRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="humidity-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={humidityRealTimeLabel.parameterType}
              parameterCategory={humidityRealTimeLabel.parameterCategory}
              deviceCategory={humidityRealTimeLabel.deviceCategory}
              readingTitle={humidityRealTimeLabel.readingTitle}
              readingUnit={humidityRealTimeLabel.readingUnit}
              uniqueID={humidityRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
          <div class="battery-realtime-parameter">
            <DelRealtimeParameterCard
              baseURL={getDomainURL()}
              currentScreen={"Dashboard"}
              nodeDetails={$selectedNode}
              parameterType={batteryRealTimeLabel.parameterType}
              parameterCategory={batteryRealTimeLabel.parameterCategory}
              deviceCategory={batteryRealTimeLabel.deviceCategory}
              readingTitle={batteryRealTimeLabel.readingTitle}
              readingUnit={batteryRealTimeLabel.readingUnit}
              uniqueID={batteryRealTimeLabel.uniqueID}
              updateTimestamp={setHighestTimestamp}
            />
          </div>
        </div>
      </div>
    </div>
    <div class="del-bottom-right-container">
      <div class="widget-container">
        <div class="trend-chart-container">
          <DelMultiAxisChart
            baseURL={getDomainURL()}
            currentScreen={"Dashboard"}
            nodeDetails={$selectedNode}
            parameterDetails={trendConfiguration.parameterDetails}
            downloadFileName={trendConfiguration.downloadFileName}
            showErrorPercentage={trendConfiguration.showErrorPercentage}
            showAnnotations={trendConfiguration.showAnnotations}
            errorLabel={trendConfiguration.errorLabel}
            errorParameterCategory={trendConfiguration.errorParameterCategory}
            annotationsList={trendConfiguration.annotationsList}
            title={trendConfiguration.title}
            chartHeight={trendConfiguration.chartHeight}
            chartColors={trendConfiguration.chartColors}
            uniqueID={trendConfiguration.uniqueID}
            dateTimeFormatForSingleDay={trendConfiguration.dateTimeFormatForSingleDay}
            dateTimeFormat={trendConfiguration.dateTimeFormat}
          />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .del-page-container {
    background-color: #f7f8fa;
    padding: 18px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  .del-left-container {
    width: 25%;
    display: flex;
    gap: 15px;
    flex-direction: column;
  }

  .left-profile-container {
    box-sizing: border-box;
  }

  .del-right-container {
    width: calc(75% - 15px);
  }

  .del-top-right-container {
    display: flex;
    justify-content: space-between;
    height: 170px;
    margin-bottom: 15px;
    gap: 15px;
  }

  .right-profile-container {
    width: calc(62% - 15px);
  }

  .widget-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
  }

  .realtime-parameter-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
    flex-grow: 1;
  }

  .alarm-widget-div {
    padding-bottom: 7px;
  }

  .alarm-tab-div {
    display: flex;
    gap: 15px;
    padding: 15px;
    padding-bottom: 0px;
    justify-content: center;
  }

  .alarm-tab-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .alarm-tab-button.selected {
    background-color: #f37a25;
    border: 1px solid #f37a25;
    color: white;
  }

  .realtime-parameter-header {
    display: flex;
    justify-content: space-between;
  }

  .widget-title {
    font: normal normal bold 18px / 24px Roboto;
    font-weight: 700 !important;
    padding: 10px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #61656c;
    position: relative;
  }

  .realtime-latest-time {
    font-size: 13px;
    color: #61656c;
    font-family: robotoregular, Arial, sans-serif;
    padding: 10px;
  }

  .parameter-card-container {
    display: flex;
    justify-content: space-around;
  }

  .trend-chart-container {
    height: 470px;
  }

  :global(.widget-normal) {
    height: max-content !important;
  }
</style>
