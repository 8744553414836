<script>
  import Loader from "../Loader/Loader.svelte";

  export let baseURL = null;
  export let currentScreen = "Dashboard";
  export let selectedNode = null;
  export let displayImage = true;

  let isAPIAwaiting = false;
  let profileData = {};
  let profileDetails = [];

  $: endpoint = `twin/api/hierarchy/${selectedNode?.id}/profile`;

  $: {
    if (selectedNode && endpoint && baseURL && currentScreen) {
      invokeAPI(
        "GET",
        endpoint,
        onGetDataAPISuccess,
        null,
        null,
        `${currentScreen}/R`
      );
    }
  }

  const onGetDataAPISuccess = function (data) {
    isAPIAwaiting = false;
    profileData = { ...data };
    profileDetails = [...profileData.details];
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyId: companyId,
            applicationId: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) options.body = JSON.stringify(payload);

      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status:${response?.status}`);
      }
      const data = await response.json();

      return data;
    }
    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.log("API call failed", error);
      });
  };
</script>

<div class="del-profile-card">
  <div class="del-profile-card-container">
    <div class="del-profile-card-header-container">
      {#if isAPIAwaiting}
        <Loader />
      {/if}

      <label class="card-title" for="" title={`${selectedNode?.name} Profile`}
        >{selectedNode?.name} Profile
      </label>

      {#if profileData.image && displayImage}
        <img
          class="del-profile-card-image"
          src={"data:image/png;base64," + profileData.image}
          alt="error"
        />
      {/if}
    </div>

    <div class="del-profile-card-inner-container">
      <table>
        {#each profileDetails as data}
          <tr>
            <td class="table-row-key">{data.title}</td>
            <td>:</td>
            <td class="table-row-value">{data.value}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-profile-card {
    font: normal normal bold 18px/24px Roboto;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
    padding: 7px 15px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    min-height: auto;
    box-sizing: border-box;
  }

  .del-profile-card-header-container {
    display: flex;
    flex-direction: column;
  }
  .card-title {
    font-weight: 700 !important;
    padding: 0 !important;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #61656c;
    margin-bottom: 10px;
    position: relative;
  }

  .del-profile-card-image {
    max-height: 200px;
    margin-bottom: 20px;
  }

  .del-profile-card-inner-container {
    margin-top: 5px;
  }
  table {
    width: 100%;
    word-break: break-all;
    hyphens: auto;
  }
  td {
    vertical-align: top;
  }
  .table-row-key {
    text-align: left;
    color: #61656c;
    width: 45%;
  }
  .table-row-value {
    text-overflow: ellipsis;
    color: #f37a25;
    text-align: left;
    width: 80%;
    position: relative;
    float: left;
  }
</style>
