<script>
  import Loader from "./../../Loader/Loader.svelte";
  import { onMount } from "svelte";
  import moment from "moment";

  export let baseURL;
  export let currentScreen;
  export let nodeId;
  export let startTime;
  export let endTime;
  export let includeIgnored;
  export let slaCategory;
  export let dataSelection;
  export let columnTitles = [];

  let payload;
  let tableData = [];
  let isDataAwaiting = false;
  let isNoData = false;

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  $: if (
    nodeId &&
    baseURL &&
    currentScreen &&
    startTime &&
    endTime &&
    slaCategory &&
    (includeIgnored || !includeIgnored)
  ) {
    fetchData();
  }

  onMount(() => {
    fetchData();
  });

  async function fetchData() {
    try {
      const url = `${baseURL}/parameters/api/SLAReport/${dataSelection}`;

      payload = {
        EndTime: endTime,
        NodeId: nodeId,
        SLACategory: slaCategory,
        StartTime: startTime,
        IncludeIgnored: includeIgnored,
      };

      if (
        !(
          payload.StartTime &&
          payload.EndTime &&
          payload.NodeId &&
          payload.SLACategory
        )
      )
        return;

      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(payload),
      };

      isDataAwaiting = true;
      const response = await fetch(url, options);
      isDataAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      const data = await response.json();
      fetchDataOnSuccess(data);
    } catch (error) {
      isDataAwaiting = false;
      console.error(error);
    }
  }

  function fetchDataOnSuccess(data) {
    tableData = data["Result"];
    if (!tableData.length) isNoData = true;
    else isNoData = false;
  }

  function formatDateTime(epoch) {
    if (epoch && epoch != 0) {
      const date = moment(epoch * 1000);
      const formattedDate = date.format("DD-MM-YYYY hh:mm:ss");
      return formattedDate;
    } else {
      return "";
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div>
  <div class="table-container">
    {#if isDataAwaiting}
      <Loader />
    {/if}
    <table>
      <thead>
        <tr>
          {#each columnTitles as title}
            <th>{title}</th>
          {/each}
        </tr>
      </thead>

      <tbody>
        {#if isNoData}
          <tr
            ><td class="nodata-text" colspan={columnTitles.length}
              >No Data Available</td
            ></tr
          >
        {:else}
          {#each tableData as { Timestamp, Reading }}
            <tr>
              <td>{formatDateTime(Timestamp)}</td>
              <td>{Reading}</td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style>
  .table-container {
    position: relative;
    min-height: 100px;
    min-width: 300px;
    border-radius: 3px;
    box-shadow: 2px 8px 8px 2px #00000014;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    background-color: #acb2b6;
    color: white;
    padding: 10px;
  }

  td {
    padding: 8px;
  }

  tr {
    border-top: 1px solid #f2f3f3;
    text-align: center;
    font-size: 13px;
    color: #61656c;
    font-family: robotoregular, Arial, sans-serif;
  }

  /* tr:hover {
    background-color: #e2fdff;
  } */

  .nodata-text {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }
</style>
