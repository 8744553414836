<script>
  import { onMount } from "svelte";
  import Icon from "@iconify/svelte";

  export let options = [];
  export let buttonLabel = "Show";
  export let checkboxTitle = "";
  export let showSubmitButton = false;
  export let isDropDownOpen = false;
  export let onShowDropDownChange = null;
  export let onSelectionUpdated = null;
  export let defaultSelection = [];
  export let isSelected = false;
  export let isDisabled = false;

  onMount(() => {
    selectedOptions = [...defaultSelection];
  });

  let selectedOptions = [];
  let isAllOptionChecked = false;
  let previousSelection = [];
  let allOptions = [];

  $: {
    if (options) {
      allOptions = options.map((item) => item.name);
    }
  }

  $: {
    if (defaultSelection) {
      updateDefaultSelection();
    }
  }

  $: {
    if (selectedOptions) {
      setAllOptions();
      if (!showSubmitButton) {
        const newSelection = options.filter((item) =>
          selectedOptions.includes(item.name)
        );
        onSelectionUpdated && onSelectionUpdated(newSelection);
      }
    }
  }

  const setAllOptions = function () {
    if (selectedOptions?.length === allOptions?.length) {
      isAllOptionChecked = true;
    } else {
      isAllOptionChecked = false;
    }
  };

  const updateDefaultSelection = function () {
    selectedOptions = [...defaultSelection];
  };

  const onAllOptionChange = function () {
    if (isAllOptionChecked) {
      selectedOptions = [...allOptions];
    } else {
      selectedOptions = [];
    }
  };

  const onButtonClick = function () {
    isDropDownOpen = !isDropDownOpen;
    if (isDropDownOpen) {
      previousSelection = [...selectedOptions];
    } else if (showSubmitButton) {
      selectedOptions = [...previousSelection];
    }
    onShowDropDownChange && onShowDropDownChange(isDropDownOpen);
  };

  const onSubmitButtonClick = function () {
    onSelectionUpdated && onSelectionUpdated(selectedOptions);
    isDropDownOpen = false;
  };
</script>

<div class="del-dropdown-checkbox-container">
  <div class="del-dropdown-button-container">
    <button
      class={`del-dropdown-button ${isDropDownOpen ? "opened" : ""} ${
        isSelected ? "selected" : ""
      }`}
      on:click={onButtonClick}
      disabled={isDisabled}
      >{buttonLabel}
      {#if isDropDownOpen}
        <Icon icon="ep:arrow-up-bold" />
      {:else}
        <Icon icon="ep:arrow-down-bold" />
      {/if}
    </button>
  </div>
  {#if isDropDownOpen}
    <div class="del-checkbox-outer-container">
      <div class="del-checkbox-container">
        <div class="del-checkbox-inner-container">
          {#if checkboxTitle}
            <div class="del-checkbox-title">{checkboxTitle}</div>
          {/if}
          <div class="del-checkbox-option select-all-option">
            <label>
              <input
                type="checkbox"
                value="select-all"
                bind:checked={isAllOptionChecked}
                on:change={onAllOptionChange}
              />All</label
            >
          </div>
          {#each allOptions as option (option)}
            <div class="del-checkbox-option">
              <label
                ><input
                  type="checkbox"
                  bind:group={selectedOptions}
                  value={option}
                  checkbox-id={option}
                />{option}</label
              >
            </div>
          {/each}
        </div>
        {#if showSubmitButton}
          <div class="del-checkbox-submit-button-container">
            <button
              class="del-checkbox-submit-button"
              on:click={onSubmitButtonClick}>Submit</button
            >
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-dropdown-checkbox-container {
    position: relative;
  }

  .del-dropdown-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-dropdown-button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  .del-dropdown-button.opened {
    border: 1px solid #79a4e9;
  }

  .del-dropdown-button.selected {
    border: none;
    background-color: #79a4e9;
    color: white;
  }

  .del-dropdown-button:hover {
    border-color: #79a4e9;
  }

  .del-checkbox-outer-container {
    position: relative;
  }

  .del-checkbox-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    color: #505050;
    padding-bottom: 20px;
  }

  .del-checkbox-container {
    position: absolute;
    z-index: 10;
    top: 2px;
    left: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
  }

  .del-checkbox-inner-container {
    padding: 15px 10px;
  }

  .del-checkbox-option {
    font: normal normal 500 13px/18px Roboto;
    color: #4d4d4d;
    text-align: left;
  }

  .del-checkbox-option label {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
  }

  .del-checkbox-option:not(:last-child),
  .select-all-option {
    padding-bottom: 15px;
  }

  input[type="checkbox"] {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .del-checkbox-submit-button-container {
    padding: 16px;
    border-top: 1px solid #ccd3e7;
    display: flex;
    justify-content: flex-end;
  }

  .del-checkbox-submit-button {
    border: 1px solid #ff6933;
    border-radius: 5px;
    font: normal normal normal 14px/19px Roboto;
    color: #222222;
    background-color: white;
    cursor: pointer;
    padding: 5px 10px;
  }
</style>
