<script>
  import Icon from "@iconify/svelte";
  import moment from "moment";
  import { exportCSVExcel, generatePDF } from "./exportReports.js";
  import Loader from "./Loader.svelte";

  export let nodeId = null;
  export let nodeName = null;
  export let startTime = null;
  export let endTime = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let possibleAlertCategories = ["Critical", "Warning", "Informational"];
  export let dateTimeFormat = "MMMM DD YYYY HH:mm:ss";
  export let showButtonForIgnore = false;
  export let writePermission = false;
  export let ignoreButtonActiveLabel = "Ignore";
  export let ignoreButtonInActiveLabel = "Ignored";
  export let onIgnoreAlertSuccess = null;
  export let onIgnoreAlertFail = null;
  export let possibleColumns = [
    "EventName",
    "Source",
    "OccuredOn",
    "EventCategory",
    "Description",
    "AlertValue",
    "CurrentStatus",
    "RemovedOn",
    "IsAcknowledged",
    "AcknowledgedBy",
    "AcknowledgedDate",
    "Comment",
    "IgnoreStatus",
    "IgnoredBy",
    "IgnoredDatetime",
    "DisablingComment",
  ];
  export let columnDetails = [];
  export let entriesPerPage = 10;
  export let showBackButton = true;
  export let onBackButtonClick = null;

  let reportData = [];
  let sortedAndFilteredData = [];
  let paginatedData = [];
  let currentPage = 1;
  let totalPages = 0;
  let showIgnoreConfirmModal = false;
  let alertToIgnore = null;
  let reportDataToDownload = [];
  let isAwaitingReportDataAPI = false;

  let eventCount = {
    Critical: 0,
    Warning: 0,
    Informational: 0,
    Total: 0,
  };

  $: {
    if (
      nodeId &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      possibleAlertCategories?.length
    ) {
      invokeGetReportDataAPI();
    }
  }

  $: {
    totalPages = Math.ceil(sortedAndFilteredData.length / entriesPerPage);
  }

  $: {
    const dataToDownload = [];
    let newRow = {};
    if (
      sortedAndFilteredData?.length &&
      possibleColumns?.length &&
      columnDetails
    ) {
      for (const row of sortedAndFilteredData) {
        newRow = {};
        for (const column of possibleColumns) {
          if (columnDetails[column] && columnDetails[column].show) {
            const columnName = columnDetails[column].displayName || column;
            newRow[columnName] = columnDetails[column].isBoolean
              ? row[column]
                ? "Yes"
                : "No"
              : column === "AlertValue"
                ? getValueWithUnit(row.AlertValue, row.Unit)
                : columnDetails[column].isDateTime
                  ? formatDateTime(
                      row[column],
                      columnDetails[column].dateTimeFormat ||
                        "HH:mm:ss DD-MMM-YYYY"
                    )
                  : row[column] ?? "";
          }
        }
        dataToDownload.push(newRow);
      }
    }
    reportDataToDownload = dataToDownload;
  }

  const onReportDataReceived = function (data) {
    if (data) {
      reportData = data?.AlertEvents || [];
      eventCount = data?.EventCount
        ? { ...data.EventCount, Total: data?.Count || 0 }
        : {
            Critical: 0,
            Warning: 0,
            Informational: 0,
            Total: 0,
          };

      sortedAndFilteredData = reportData;
      updatePagination();
    }
  };

  async function getResponse(endpoint, payload, method = "POST") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const invokeGetReportDataAPI = function () {
    if (
      typeof localStorage === "undefined" ||
      !(nodeId && startTime && endTime && currentScreen && baseURL)
    ) {
      return;
    }
    reportData = [];
    eventCount = {
      Critical: 0,
      Warning: 0,
      Informational: 0,
      Total: 0,
    };

    const payload = {
      StartTime: startTime,
      EndTime: endTime,
      IncludeChildren: true,
      NodeId: nodeId,
      AlertCategory: possibleAlertCategories,
    };

    const endpoint = `alert/api/configuration/alerthistory`;
    isAwaitingReportDataAPI = true;
    getResponse(endpoint, payload)
      .then((data) => {
        isAwaitingReportDataAPI = false;
        onReportDataReceived(data);
      })
      .catch((error) => {
        isAwaitingReportDataAPI = false;
        console.error("API call failed", error);
      });
  };

  const ignoreAlert = function (alert) {
    const eventId = alert.EventId || "";
    const endpoint = `alert/api/Configuration/events/${eventId}/ignore`;
    const payload = {
      IgnoredBy: localStorage.getItem("uname") || "",
    };
    getResponse(endpoint, payload, "PATCH")
      .then((data) => {
        onIgnoreAlertSuccess && onIgnoreAlertSuccess(data);
        invokeGetReportDataAPI();
      })
      .catch((error) => {
        onIgnoreAlertFail && onIgnoreAlertFail(error);
      });

    alertToIgnore = null;
  };

  const formatDateTime = function (value, format) {
    const dateFormat = format ? format : dateTimeFormat;
    return !value || isNaN(value)
      ? ""
      : moment(new Date(parseInt(value * 1000))).format(dateFormat);
  };

  const convertToTwoDigits = function (value) {
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const getValueWithUnit = function (value, unit) {
    if (isNaN(value) || value === null) {
      return "";
    }
    if (unit && unit !== "Number") {
      return `${convertToTwoDigits(value)} ${unit}`;
    } else {
      return convertToTwoDigits(value);
    }
  };

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = sortedAndFilteredData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const handleReportExport = function (exportType) {
    const startTimeFormatted = formatDateTime(startTime);
    const endTimeFormatted = formatDateTime(endTime);
    let eventCountForExport = {};
    for (let item of possibleAlertCategories) {
      eventCountForExport[`${item} Count`] = eventCount[item];
    }
    eventCountForExport["Total Count"] = eventCount["Total"];
    if (exportType === "pdf") {
      const columnHeaders = possibleColumns
        .filter((item) => columnDetails[item]?.show)
        .map((item) => columnDetails[item]?.displayName || item);
      generatePDF(
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        eventCountForExport,
        reportDataToDownload,
        columnHeaders,
        columnDetails["EventCategory"].displayName || "EventCategory",
        columnDetails["CurrentStatus"].displayName || "CurrentStatus",
        columnDetails["Comment"].displayName || "Comment",
        columnHeaders.length
      );
    } else {
      exportCSVExcel(
        exportType,
        "Event Report",
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        eventCountForExport,
        reportDataToDownload
      );
    }
  };
</script>

<div class="del-event-report-container">
  <div class="del-events-report-header-container">
    {#if isAwaitingReportDataAPI}
      <Loader />
    {/if}
    <div
      class="del-events-report-header-inner-container del-events-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-events-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-events-report-header-main-title">Event Report</div>
          <div class="del-events-report-header-sub-title">
            Report of {nodeName}
          </div>
        </div>
      </div>
      <div class="del-event-report-header-right-container">
        <div>
          <div class="del-events-report-header-titles">Download</div>
          <div class="flex-container del-events-table-export-buttons-container">
            <button
              name="csvDownloadButton"
              title={sortedAndFilteredData?.length
                ? "Export CSV"
                : "No data to download"}
              class="del-events-table-export-button csv-export-button"
              on:click={handleCSVExportButtonClick}
              disabled={!sortedAndFilteredData?.length}
            />
            <button
              name="excelDownloadButton"
              title={sortedAndFilteredData?.length
                ? "Export Excel"
                : "No data to download"}
              class="del-events-table-export-button excel-export-button"
              on:click={handleExcelExportButtonClick}
              disabled={!sortedAndFilteredData?.length}
            />
            <button
              name="pdfDownloadButton"
              title={sortedAndFilteredData?.length
                ? "Export PDF"
                : "No data to download"}
              class="del-events-table-export-button pdf-export-button"
              on:click={handlePDFExportButtonClick}
              disabled={!sortedAndFilteredData?.length}
            />
          </div>
        </div>
        <div>
          <div
            class="del-events-report-header-titles del-events-report-summary-title"
          >
            Report Summary
          </div>
          <div
            class="del-events-report-header-titles del-events-report-header-small-titles del-events-report-header-dates"
          >
            {`From : ${formatDateTime(startTime)}`}
          </div>
          <div
            class="del-events-report-header-titles del-events-report-header-small-titles del-events-report-header-dates"
          >
            {`To : ${formatDateTime(endTime)}`}
          </div>
        </div>
      </div>
    </div>
    <div
      class="del-events-report-header-inner-container del-events-report-header-container-bottom"
    >
      {#each possibleAlertCategories as item (item)}
        <div
          class="del-events-report-header-titles del-events-report-header-small-titles"
        >
          {`${item} Count : ${eventCount[item]}`}
        </div>
      {/each}

      <div
        class="del-events-report-header-titles del-events-report-header-small-titles"
      >
        {`Total Count : ${eventCount.Total}`}
      </div>
    </div>
  </div>
  <div class="del-events-table-container">
    <!-- Report Table -->
    {#if sortedAndFilteredData?.length}
      <div class="del-events-table-inner-container">
        <table class="del-events-table">
          <thead>
            {#if possibleColumns?.length}
              <tr class="del-events-table-header-row">
                {#each possibleColumns as item (item)}
                  {#if columnDetails[item]?.show}
                    <th
                      class="del-events-table-cell del-events-table-header-cell"
                      >{columnDetails[item]?.displayName || item}</th
                    >
                  {/if}
                {/each}
              </tr>
            {/if}
          </thead>
          <tbody class="table-body">
            {#each paginatedData as row}
              <tr class="del-events-table-row">
                {#each possibleColumns as item (item)}
                  {#if columnDetails[item]?.show}
                    {#if item === "IgnoreStatus" && showButtonForIgnore && writePermission}
                      <td class="del-events-table-cell">
                        <button
                          name={`button-for-${item}`}
                          disabled={row[item]}
                          class="del-events-report-table-button"
                          on:click={() => {
                            alertToIgnore = row;
                            showIgnoreConfirmModal = true;
                          }}
                          >{row[item]
                            ? ignoreButtonInActiveLabel
                            : ignoreButtonActiveLabel}</button
                        >
                      </td>
                    {:else if columnDetails[item]?.isDateTime}
                      <td class="del-events-table-cell">
                        {#if row[item]}
                          <span class="del-events-table-time"
                            >{formatDateTime(
                              row[item],
                              columnDetails[item].timeFormat || "HH:mm:ss"
                            )}</span
                          >
                          <span class="del-events-table-date"
                            >{formatDateTime(
                              row[item],
                              columnDetails[item].dateFormat || "DD-MMM-YYYY"
                            )}</span
                          >
                        {:else}
                          {""}
                        {/if}
                      </td>
                    {:else if columnDetails[item]?.isBoolean}
                      <td class="del-events-table-cell">
                        {row[item] ? "Yes" : "No"}
                      </td>
                    {:else if item === "AlertValue"}
                      <td class="del-events-table-cell"
                        >{getValueWithUnit(row.AlertValue, row.Unit)}</td
                      >
                    {:else}
                      <td
                        class={`del-events-table-cell ${
                          item === "CurrentStatus"
                            ? row[item] === "Active"
                              ? "del-events-table-event-active"
                              : "del-events-table-event-inactive"
                            : item === "EventCategory"
                              ? row[item] === "Critical"
                                ? "del-events-table-event-critical"
                                : row[item] === "Warning"
                                  ? "del-events-table-event-warning"
                                  : row[item] === "Informational"
                                    ? "del-events-table-event-informational"
                                    : ""
                              : ""
                        }`}>{row[item]}</td
                      >
                    {/if}
                  {/if}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {:else}
      <div class="no-data-message">No Data Available</div>
    {/if}
    <!-- Pagination -->
    {#if sortedAndFilteredData.length > entriesPerPage}
      <div class="pagination-container">
        <div class="entries-display">
          {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
            currentPage * entriesPerPage,
            sortedAndFilteredData.length
          )} of ${sortedAndFilteredData.length} entries`}
        </div>
        <div class="pagination-buttons">
          <button
            class="first-page"
            disabled={currentPage === 1}
            on:click={handleFirstPageClick}>First</button
          >
          <button
            class="previous-page"
            disabled={currentPage === 1}
            on:click={handlePreviousPageClick}>Previous</button
          >
          <span class="current-page"
            >{`Page ${currentPage} of ${totalPages}`}</span
          >
          <button
            class="next-page"
            disabled={currentPage === totalPages}
            on:click={handleNextPageClick}>Next</button
          >
          <button
            class="last-page"
            disabled={currentPage === totalPages}
            on:click={handleLastPageClick}>Last</button
          >
        </div>
      </div>
    {/if}
    {#if showIgnoreConfirmModal}
      <div class="del-event-viewer-modal">
        <div class="del-event-viewer-modal-header" modal-type="ignore">
          <div class="del-event-viewer-modal-title">Ignore Event</div>
        </div>
        <div class="del-event-viewer-modal-ignore-container">
          Are you sure you want to ignore this event?
        </div>
        <div class="del-event-viewer-modal-buttons-container">
          <button
            class="del-event-viewer-modal-cancel-button"
            id="del-event-viewer-modal-cancel-button"
            on:click={() => {
              showIgnoreConfirmModal = false;
              alertToIgnore = null;
            }}>Cancel</button
          >
          <button
            class="del-event-viewer-modal-submit-button"
            id="del-event-viewer-modal-submit-button"
            on:click={() => {
              showIgnoreConfirmModal = false;
              ignoreAlert(alertToIgnore);
            }}>Ignore</button
          >
        </div>
      </div>
    {/if}
  </div>
</div>
<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-event-report-container {
    position: relative;
  }
  .del-events-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px;
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-events-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0;
    margin-top: 20px;
  }

  .del-events-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-events-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./../images/headerBackground.svg");
  }

  .del-events-report-header-container-bottom {
    gap: 15px;
    justify-content: start;
  }

  .del-events-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-events-report-header-back-button:enabled:hover,
  .del-events-report-header-back-button:enabled:active,
  .del-events-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-events-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-events-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-events-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-events-report-header-small-titles {
    font-size: 14px;
  }

  .del-events-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-events-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-events-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-events-table-export-button:enabled:hover,
  .del-events-table-export-button:enabled:active,
  .del-events-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./../images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./../images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./../images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .del-events-report-summary-title {
    margin-bottom: 10px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .del-events-report-header-dates {
    text-align: right;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  .del-event-report-header-right-container {
    display: flex;
    gap: 80px;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  /* For Report Table */
  .del-events-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
  }
  .del-events-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-events-table td:first-child,
  .del-events-table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    border-right: 1px solid #d3d3d3;
    min-width: 300px;
  }

  .del-events-table th:last-child {
    border-right-color: #79919a;
  }

  .del-events-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #000000;
    opacity: 0.77;
    min-width: 250px;
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-events-table-no-data-cell {
    text-align: center;
  }

  .del-events-table-header-row {
    border: 1px solid #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-events-table-filter-row {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #beccd1;
    border: 1px solid #d3d3d3;
  }

  .del-events-table-header-cell {
    border-color: #79919a;
    border-right-color: #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }
  .del-events-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .del-events-table-event-critical {
    color: #e1464a;
  }

  .del-events-table-event-warning {
    color: #fcb900;
  }

  .del-events-table-event-informational {
    color: #4caf50;
  }

  .del-events-table-event-active {
    color: #ff5555;
  }

  .del-events-table-event-inactive {
    color: #4b72b9;
  }

  .del-events-report-table-button {
    color: #fff;
    background-color: #2c3049;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
  }

  .del-events-report-table-button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .del-events-report-table-button:enabled:hover {
    background: #222639;
  }

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */

  /* For Modal */
  .del-event-viewer-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 25%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-event-viewer-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }
  .del-event-viewer-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
    margin-top: 10px;
  }

  .del-event-viewer-modal-ignore-container {
    font: normal normal bold 15px Roboto;
    color: #000000;
    padding: 30px 0;
  }
  button.del-event-viewer-modal-submit-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-event-viewer-modal-cancel-button {
    color: #e51f1f;
    background: transparent;
    border: none;
    font: normal normal bold 14px Roboto;
    cursor: pointer;
  }

  @media screen and (min-width: 700px) {
    .del-event-viewer-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 25%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-event-viewer-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 25%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-event-viewer-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-event-viewer-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-event-viewer-modal {
      top: 25%;
    }
  }

  /* End of modal styles */
</style>
