<script>
  import { onMount } from "svelte";
  import Loader from "./../../Loader/Loader.svelte";
  import ApexCharts from "apexcharts";

  export let baseURL;
  export let nodeDetails;
  export let startTime = 0;
  export let endTime = 0;
  export let currentScreen = "Dashboard";
  export let IncludeIgnored = false;
  export let slaCategory = "";
  export let legendPosition = "bottom";
  export let legendAlignment = "center";
  export let showLegend;
  export let chartHeight = "800px";
  export let custom;
  export let sortOrder = ["Normal", "Informational", "Warning", "Critical"];
  export let selectColors = {
    Normal: "#59A047",
    Informational: "#92D050",
    Warning: "#F8C506",
    Critical: "#E64D36",
  };
  let isAPIAwaiting = false;
  let responseData = null;
  let chartElement = null;
  let chart = null;

  $: endpoint = custom
    ? "parameters/api/SLAReport/custom"
    : "parameters/api/SLAReport";

  $: {
    if (
      baseURL &&
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      slaCategory &&
      (IncludeIgnored || !IncludeIgnored)
    )
      invokeAPI(
        "POST",
        endpoint,
        onGetDataAPISuccess,
        null,
        `${currentScreen}/R`
      );
  }
  onMount(() => {
    if (responseData) drawPie(responseData);
  });

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    accessOrigin,
    extraHeader = {}
  ) {
    async function getResponse() {
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && applicationId && companyId && accessOrigin)) return;
      let headers = {
        ...extraHeader,
        companyId: companyId,
        applicationId: applicationId,
        "access-origin": accessOrigin,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      let payload = {
        EndTime: endTime,
        IncludeIgnored: IncludeIgnored,
        NodeId: nodeDetails.id,
        SLACategory: slaCategory,
        StartTime: startTime,
      };

      let options = { method, headers };
      if (payload) options.body = JSON.stringify(payload);
      isAPIAwaiting = true;
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status:${response?.status}`);
      }
      const data = await response.json();
      return data;
    }
    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        isAPIAwaiting = false;
        console.log("API call failed", error);
      });
  };

  const onGetDataAPISuccess = function (data) {
    responseData = Object.entries(data).map(([key, value]) => {
      return { name: key, value };
    });
    isAPIAwaiting = false;
    drawPie(responseData);
  };

  function drawPie() {
    responseData?.sort((a, b) => {
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
    });

    let setRangeForNormal = function (value) {
      let min, max, temp;
      if (value == "Normal") {
        if (slaCategory == "Temperature") {
          temp = responseData.find((item) => item.name === "Warning");
          min = temp.value.AlertRule?.Max;
          temp = responseData.find((item) => item.name === "Informational");
          max = temp.value.AlertRule?.Min;
        } else if (slaCategory == "Humidity") {
          temp = responseData.find((item) => item.name === "Warning");
          max = temp.value.AlertRule?.Min;
          temp = responseData.find((item) => item.name === "Informational");
          min = temp.value.AlertRule?.Max;
        } else {
          temp = responseData.find((item) => item.name === "Informational");
          min = temp.value.AlertRule?.Min;
          max = 0;
        }
      }
      return { min, max };
    };
    let setUnit = function () {
      if (slaCategory == "Temperature") return "°C";
      else if (slaCategory == "Humidity") return "%";
      else return "PPM";
    };
    let Names = responseData?.map((item) => {
      let unit = setUnit();

      if (item?.name == "Warning")
        return `% of Total Time below ${item.value.AlertRule?.Min} ${unit} and above ${item.value.AlertRule?.Max}${unit}`;
      else if (item?.name == "Normal") {
        let data = setRangeForNormal(item.name);
        if (slaCategory == "Temperature" || slaCategory == "Humidity") {
          return `% of Total Time Between ${data.min} ${unit} - ${data.max} ${unit}`;
        } else {
          return `Below ${data?.min} ${unit}`;
        }
      } else
        return `% of Total Time Between ${item.value.AlertRule?.Min} ${unit} - ${item.value.AlertRule?.Max} ${unit}`;
    });
    let seriesValues = responseData?.map((item) => {
      return item.value?.PercentageValue;
    });
    let Colors = responseData?.map((item) => {
      return selectColors[item.name];
    });
    let options = {
      horizontalAlign: "center",
      series: seriesValues,
      chart: {
        width: "350px",
        height: chartHeight,
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      labels: Names,
      colors: Colors,
      legend: {
        show: showLegend,
        horizontalAlign: legendAlignment,
        position: legendPosition,

        formatter: function (seriesName, opts) {
          return [
            opts.w.globals.series[opts.seriesIndex].toFixed(2),
            seriesName,
          ];
        },
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        fontSize: "12px",
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
        style: {
          fontSize: "12px",
        },
      },
    };
    if (!chartElement) {
      console.log("No element");
      return;
    }
    if (!chart) {
      chart = new ApexCharts(chartElement, options);
      chart.render();
    } else {
      chart.updateOptions(options);
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-pie-chart-container">
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  <div class="del-pie-chart" bind:this={chartElement} />
</div>

<style>
  .del-pie-chart-container {
    position: relative;
    max-width: 480px;
    height: auto;
    display: flex;
    flex-direction: column;
    font: normal normal bold 18px/24px Roboto;
  }

  .del-pie-chart {
    max-width: 650px;
    margin: 15px auto;
    height: 300px;
  }
</style>
