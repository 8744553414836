<script>
  import { selectedNode } from "../stores";
  import { getDomainURL } from "../invokeAPI";
  import DelSetAlarm from "./../components/DelSetAlarm/DelSetAlarm.svelte";
  import { setAlarmConfig } from "./../../config";

  $: selectedNodeId = $selectedNode?.id;
  $: console.log(selectedNodeId);
</script>

<div class="del-page-conatiner">
  <DelSetAlarm
    baseURL={getDomainURL()}
    currentScreen={"Dashboard"}
    NodeID={selectedNodeId}
    rangeWidgetConfiguration={setAlarmConfig.rangeWidgetConfiguration}
    tabOptions={setAlarmConfig.tabOptions}
  />
</div>
